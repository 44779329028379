import { Box, Flex, Link } from '@chakra-ui/react';
import moment from 'moment';
import { useHistory, useLocation, useParams } from 'react-router-dom';

import Description from '../../components/Description';
import Title from '../../components/Title';
import PrimaryButton from '../../components/ui/buttons/PrimaryButton';
import AuthorImageComponent from '../Sanity/Author/AuthorImageComponent';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { BlogPostSnippetProps } from '../Sanity/Model';

/* eslint-disable react/destructuring-assignment */

const BlogPostSnippet = (props: BlogPostSnippetProps) => {
  const location = useLocation();
  const history = useHistory();
  const params: {
    tag: string;
  } = useParams();
  return (
    <>
      <Box
        flexDirection="column"
        mt={{
          base: props.post.featured ? 0 : '15px',
          lg: props.post.featured ? 0 : '30px',
        }}
        mb={{
          base: props.post.featured ? '15px' : 0,
          lg: props.post.featured ? '30px' : 0,
        }}
        pt={{
          base: props.post.featured ? 0 : '15px',
          lg: props.post.featured ? 0 : '30px',
        }}
        p={{
          base: props.post.featured ? '15px' : 0,
          lg: props.post.featured ? '30px' : 0,
        }}
        borderTopWidth={{
          base: props.post.featured ? 0 : 1,
        }}
        borderWidth={{
          base: props.post.featured ? 1 : 0,
        }}
        borderRadius={8}
      >
        {props.post.featured && <Flex>Featured Blog</Flex>}
        <Title
          textAlign="left"
          fontSize={{
            base: '16pt',
            lg: '20pt',
          }}
          cursor="pointer"
          mt={props.post.featured ? 0 : 'inherit'}
          onClick={() => history.push(`/blog/${props.post.slug.current}`)}
        >
          {props.post.title}
        </Title>
        {!props.post.featured && (
          <Flex justifyContent="flex-start" alignItems="center">
            {props.post.authorImage && (
              <Flex me={2}>
                <AuthorImageComponent value={props.post.authorImage} isInline />
              </Flex>
            )}
            <Link
              href={`/author/${props.post.authorSlug}?ref=${location.pathname}`}
              fontSize="14pt"
            >
              {props.post.authorName}
            </Link>
          </Flex>
        )}
        <Flex mt={4} mb={2}>
          {moment(new Date(props.post.publishDate)).format('M/D/YY h:mma')}
        </Flex>
        <Flex>
          <Flex flexDirection="column" flexGrow={1}>
            <Description>{props.post.snippet}</Description>
            {props.post.categories && props.post.categories.length && (
              <>
                <Box mt={4} mb={4}>
                  {props.post.categories.map((category: string) => {
                    return (
                      <Link
                        me={1}
                        p={1}
                        px={2}
                        key={category}
                        href={`/blog/tag/${category}`}
                        style={{
                          fontSize: 12,
                          fontWeight: 600,
                          backgroundColor:
                            category === params.tag
                              ? '#087dc5'
                              : params.tag
                              ? 'white'
                              : 'lightgray',
                          color:
                            category === params.tag ? 'white' : params.tag ? '#888' : '#454545',
                          borderRadius: 4,
                        }}
                      >
                        {category}
                      </Link>
                    );
                  })}
                </Box>
              </>
            )}
          </Flex>
          {props.post.mainImage && (
            <Flex width={300}>
              <BlogImageComponent value={props.post.mainImage} isInline flavor="snippet" />
            </Flex>
          )}
        </Flex>
        <Flex>
          <PrimaryButton onClick={() => history.push(`/blog/${props.post.slug.current}`)}>
            READ MORE
          </PrimaryButton>
        </Flex>
      </Box>
    </>
  );
};

export default BlogPostSnippet;

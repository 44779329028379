import AuthorImageComponent from './Author/AuthorImageComponent';
import BlogImageComponent from './Blog/BlogImageComponent';

export interface SanityPageProps {
  heading: string;
  subtitle: string;
  widget: boolean;
  body: [];
}

export interface FAQProps {
  question: string;
  answer: [];
}

export interface BlogPostProps {
  description: string;
  browserTitle: string;
  keywords: string;
  title: string;
  slug: string;
  publishDate: string;
  authorName: string;
  authorSlug: string;
  authorImage: string;
  categories: string[];
  body: [];
  mainImage?: string;
}

export interface FeaturedPostProps {
  title: string;
  slug: {
    current: string;
  };
  publishDate: string;
  authorName: string;
  authorSlug: string;
  authorImage: string;
  snippet: string;
  categories: string[];
  mainImage?: string;
}

export interface BlogPostSnippetProps {
  post: {
    title: string;
    slug: {
      current: string;
    };
    publishDate: string;
    authorName: string;
    authorSlug: string;
    authorImage: string;
    snippet: string;
    categories: string[];
    mainImage?: string;
    featured?: number;
  };
}

export interface AuthorProps {
  authorSlug: string;
  authorName: string;
  authorImage: string;
  authorBio: [];
  posts: [];
}

export const ptBlogComponents = {
  types: {
    image: BlogImageComponent,
  },
};

export const ptAuthorComponents = {
  types: {
    image: AuthorImageComponent,
  },
};

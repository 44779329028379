import { createStandaloneToast } from '@chakra-ui/react';
import { useCallback } from 'react';

import { checkboxReCaptchaRef, invisibleReCaptchaRef, isReCaptchaEnabled } from '.';
import customTheme from '../../chakra';
import { LDFlags } from '../../constants/experiments';
import useFlag from '../../hooks/useFlag';
import { createErrorToast } from '../../utils/toast';

interface Props {
  type: 'invisible' | 'checkbox';
}

export const useReCaptcha = ({ type }: Props) => {
  const isReCaptchaFlagEnabled = useFlag(LDFlags.RE_CAPTCHA_ENABLED);

  const generateReCaptchaToken = useCallback(async () => {
    const randomString = Math.random().toString(36).substring(2);

    if (!isReCaptchaEnabled(isReCaptchaFlagEnabled)) {
      return randomString;
    }

    let token: string | undefined | null = null;
    if (type === 'invisible') {
      token = await invisibleReCaptchaRef.current?.executeAsync();
      invisibleReCaptchaRef?.current?.reset();
    } else if (type === 'checkbox') {
      token = await checkboxReCaptchaRef.current?.getValue(); // .executeAsync() is only for invisible
      checkboxReCaptchaRef?.current?.reset();
    }

    if (token) {
      return token;
    }

    const { toast } = createStandaloneToast({ theme: customTheme });
    toast(
      createErrorToast({
        errorMessage: 'reCAPTCHA Error',
        title: 'There was an error with reCAPTCHA.',
      }),
    );

    return randomString;
  }, [isReCaptchaFlagEnabled]);

  return { generateReCaptchaToken };
};

import { useLocation } from 'react-router-dom';

import { MAIN_NUMBER } from '../constants/contact';
import { BEST_MONEY } from '../constants/urls';
import { LocalStorageKeys, getLocalStorageItem } from '../services/localStorage';
import { useReadLocalStorage } from './useLocalStorage';

const PAID_SEARCH = 'paid-search';
const CALL_CAMPAIGN = 'call-campaign';
const GOOGLE_DISPLAY = 'google-display';
const GOOGLE_VIDEO = 'google-video';
const FACEBOOK = 'facebook';
const TIKTOK = 'tiktok';
const PMAX = 'pmax';
const CONSUMER_VOICE = 'consumervoice';
const VERSE = 'verse';
const CJ = 'cj';

const getPhoneNumberHelper = (phone: string | null) => {
  if (!phone) {
    return MAIN_NUMBER;
  }
  switch (phone.toLowerCase()) {
    case FACEBOOK:
      return '(833) 723-0080';
    case GOOGLE_DISPLAY:
      return '(833) 700-9901';
    case GOOGLE_VIDEO:
      return '(833) 965-0600';
    case PAID_SEARCH:
      return '(844) 334-4331';
    case CALL_CAMPAIGN:
      return '(833) 902-2226';
    case TIKTOK:
      return '(844) 670-0660';
    case PMAX:
      return '(833) 963-3366';
    case CONSUMER_VOICE:
      return '(877) 425-0460';
    case VERSE:
      return '(855) 582-0110';
    case CJ:
      return '(833) 988-1699';
    default:
      return MAIN_NUMBER;
  }
};

const getPhoneFromParamString = (query_string: string) => {
  const params = new URLSearchParams(query_string);

  let phone = params.get('phone');
  if (!phone) {
    phone = params.get('source');
    if (!phone) {
      phone = params.get('utm_source');
    }
  }

  return phone;
};

export const getPhoneNumber = () => {
  const params = getLocalStorageItem<string>(LocalStorageKeys.QUERY_PARAMS) || '';
  const phone = getPhoneFromParamString(params);

  return getPhoneNumberHelper(phone as string);
};

const useSearchPhoneNumbers = () => {
  const { pathname, search } = useLocation();
  let phone = getPhoneFromParamString(search);

  const savedQuery = useReadLocalStorage<string>(LocalStorageKeys.QUERY_PARAMS) || '';
  const savedPhone = getPhoneFromParamString(savedQuery);
  if (savedPhone) {
    phone = savedPhone;
  }

  if (pathname === BEST_MONEY) {
    phone = CJ;
  }

  return getPhoneNumberHelper(phone);
};

export default useSearchPhoneNumbers;

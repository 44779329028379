interface Config {
  baseUrl: string;
  flowUrl: string;
  auth0: {
    internalDomain: string;
    internalClientId: string;
    externalDomain: string;
    externalClientId: string;
    audience: string;
  };
  urls: {
    apiRoot: string;
    wsRoot: string;
    prsApiRoot: string;
  };
  launchDarklyClientId: string;
  mixpanelTokenId: string;
  gtm: {
    gtmId: string;
    auth: string;
    preview: string;
  };
  rudderstack: {
    writeKey: string;
    dataPlaneUrl: string;
  };
  stripe: {
    publishableKey: string;
  };
}
type Environments = 'test' | 'development' | 'staging' | 'production';

type Configs = {
  [env in Environments]: Config;
};

// this helps test locally on mobile devices
// replace with your ip address; Mac: open terminal and type ipconfig getifaddr en0
// You'll need to also add this the baseUrl(http://${devBase}:3001) to the Application URIs/Allowed Origins in the auth0 dashboard
const devBase = 'localhost';

const configs: Configs = {
  test: {
    baseUrl: 'https://mkt.staging.leaseend.com',
    flowUrl: 'https://my.staging.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      internalClientId: 'mXTqm3vgky1Cgdjp1rXCsA9onlJ0W3mF',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      apiRoot: 'http://localhost:8080',
      wsRoot: 'ws://localhost:8080',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '',
    mixpanelTokenId: '',
    gtm: {
      gtmId: '',
      auth: '',
      preview: '',
    },
    rudderstack: {
      writeKey: '',
      dataPlaneUrl: '',
    },
    stripe: {
      publishableKey: '',
    },
  },
  development: {
    baseUrl: `http://${devBase}:3002`,
    flowUrl: `http://${devBase}:3001`,
    auth0: {
      internalDomain: 'auth.leaseend.com',
      internalClientId: 'mXTqm3vgky1Cgdjp1rXCsA9onlJ0W3mF',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      apiRoot: `http://${devBase}:8080`,
      wsRoot: `ws://${devBase}:8080`,
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
    mixpanelTokenId: 'd5011870fa04215a4390c1dbf87f8dad',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'bgchO0mYpBcZfNkiuwRMpg',
      preview: 'env-4',
    },
    rudderstack: {
      writeKey: '2TgHgn22nZ04uuhkbu4tylufRK2',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
    },
    stripe: {
      publishableKey:
        'pk_test_51OKlIMH7oFM6RZRIcCxQegvl5jT2Lf3TR7L4ZcsIpzWMmr6MfFJX29FpSZRmLKAjwseALWCMp7QlqrKJPLi9gn3y00CPe0MbNz',
    },
  },
  staging: {
    baseUrl: 'https://mkt.staging.leaseend.com',
    flowUrl: 'https://my.staging.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      internalClientId: 'mXTqm3vgky1Cgdjp1rXCsA9onlJ0W3mF',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      apiRoot: 'https://api.staging.leaseend.com',
      wsRoot: 'wss://api.staging.leaseend.com',
      prsApiRoot: 'https://payoff.staging.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e1',
    mixpanelTokenId: 'd5011870fa04215a4390c1dbf87f8dad',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'ulxXBrhuMcRevnSvDodg3A',
      preview: 'env-5',
    },
    rudderstack: {
      writeKey: '2TgHgn22nZ04uuhkbu4tylufRK2',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
    },
    stripe: {
      publishableKey:
        'pk_test_51OKlIMH7oFM6RZRIcCxQegvl5jT2Lf3TR7L4ZcsIpzWMmr6MfFJX29FpSZRmLKAjwseALWCMp7QlqrKJPLi9gn3y00CPe0MbNz',
    },
  },
  production: {
    baseUrl: 'https://www.leaseend.com',
    flowUrl: 'https://my.leaseend.com',
    auth0: {
      internalDomain: 'auth.leaseend.com',
      internalClientId: 'mXTqm3vgky1Cgdjp1rXCsA9onlJ0W3mF',
      externalDomain: 'auth-external.leaseend.com',
      externalClientId: 'sYRfwn3aLpgaLXEM3EZfFao1BsShvGlZ',
      audience: 'https://api.leaseend.com/graphql',
    },
    urls: {
      apiRoot: 'https://api.leaseend.com',
      wsRoot: 'wss://api.leaseend.com',
      prsApiRoot: 'https://payoff.leaseend.com',
    },
    launchDarklyClientId: '618400a0e330d26355a8e1e2',
    mixpanelTokenId: '6af0e65375638b2b8a720bc69cdc0971',
    gtm: {
      gtmId: 'GTM-PSLHHMM',
      auth: 'AkAE3X1T7WsBjCHxN-ROwQ',
      preview: 'env-1',
    },
    rudderstack: {
      writeKey: '2ThxH3bH4rZEYGkKriUCMUIrp5D',
      dataPlaneUrl: 'https://leaseenddiodkq.dataplane.rudderstack.com',
    },
    stripe: {
      publishableKey:
        'pk_live_51OKlIMH7oFM6RZRIjqQKpwXUtKqeBjZ0f9xcliC6fQdLzzbVFRxnCqGjgczpf9zcblBVOBUsix9dfuvmbiXwrMol00MSFvjrM2',
    },
  },
};

export const getEnv = () => process.env.REACT_APP_STAGE || process.env.NODE_ENV;
export const isLocal = getEnv() === 'development';
export const isStaging = getEnv() === 'staging';
export const isProd = getEnv() === 'production';

export default configs[getEnv() as Environments];

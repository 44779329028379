import { Box } from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';

import config from './config';
import Routes from './routes/Routes';

mixpanel.init(config.mixpanelTokenId);

const App = () => {
  return (
    <Box w="w-screen" overflowX="hidden">
      <Routes />
    </Box>
  );
};

export default App;

import { BoxProps, Button, Flex, Text } from '@chakra-ui/react';
import { Form, Formik, FormikContextType } from 'formik';
// eslint-disable-next-line no-restricted-imports
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import * as Yup from 'yup';

import { LDFlags } from '../constants/experiments';
import { statesArray } from '../constants/states';
import { ACCOUNT_LOGIN, FLOW_ENTRY } from '../constants/urls';
import { INVALID_VIN_MSG } from '../constants/vin';
import { TemporaryShoppingCartDataInput } from '../gql/generated/graphql';
import { CookieKeys, useCookie } from '../hooks/useCookie';
import useFlag from '../hooks/useFlag';
import { RudderEvent, rudderanalytics } from '../utils/rudderstack';
import { NonNullRequired } from '../utils/types/typeHelpers';
import validateLicenseNumber, {
  INVALID_LICENSE_PLATE_MSG,
} from '../utils/validation/licenseNumberValidator';
import validateVin from '../utils/validation/vinValidator';
import Subtitle from './Subtitle';
import Switch from './Switch';
import Input from './formComponents/Input';
import Select from './formComponents/Select';
import { Link } from './ui/links/Link';

const YEAR_DIFF = 8;

const licensePlateValidationSchema = Yup.object({
  license_plate_number: Yup.string()
    .required('Required field')
    .test('is-valid-license-number', INVALID_LICENSE_PLATE_MSG, async (value, context) =>
      validateLicenseNumber({ licenseNumber: value, state: context.parent.state, context }),
    ),
  state: Yup.string().required('Required field'),
});

const vinValidationSchema = Yup.object({
  vin: Yup.string()
    .required('Required field')
    .test('is-valid-vin', INVALID_VIN_MSG, (value) => validateVin(value)),
});

const LICENSE_PLATE_NAME = 'PLATE';
const VIN_NAME = 'VIN';

type FormFields = Yup.InferType<typeof licensePlateValidationSchema & typeof vinValidationSchema>;
type FormikContext = FormikContextType<FormFields>;
type setErrors = FormikContext['setErrors'];
type setValues = FormikContext['setValues'];

export const SHOPPING_CART_PARAMS = {
  PAYOFF: 'payoff',
  CREDIT_SCORE: 'credit_score',
  DOWN_PAYMENT: 'down_payment',
  TERM: 'term',
};

// will need to be synced with com
export const FLOW_ENTRY_PARAMS = {
  ZIP: 'zip',
  VIN: 'vin',
  LICENSE_PLATE: 'license_plate',
  STATE: 'state',
};

const createQueryParams = (
  values: FormFields,
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>,
  zip?: string,
) => {
  const { vin, license_plate_number, state } = values;
  const { buyout, credit_score, down_payment, loan_term } = shoppingCart || {};

  const params = {
    [FLOW_ENTRY_PARAMS.LICENSE_PLATE]: license_plate_number,
    [FLOW_ENTRY_PARAMS.STATE]: state,
    [FLOW_ENTRY_PARAMS.VIN]: vin,
    [FLOW_ENTRY_PARAMS.ZIP]: zip,
    [SHOPPING_CART_PARAMS.PAYOFF]: buyout,
    [SHOPPING_CART_PARAMS.CREDIT_SCORE]: credit_score,
    [SHOPPING_CART_PARAMS.DOWN_PAYMENT]: down_payment,
    [SHOPPING_CART_PARAMS.TERM]: loan_term,
  };

  const cleanedParams = Object.entries(params).reduce((acc, [key, value]) => {
    if (value === undefined) return acc;
    return { ...acc, [key]: value.toString() };
  }, {});

  return new URLSearchParams(cleanedParams).toString();
};

interface LicensePlateOrVinInputProps extends BoxProps {
  autoFocus?: boolean;
  shoppingCart?: NonNullRequired<TemporaryShoppingCartDataInput>;
  zip?: string;
}

const LicensePlateOrVinInput = ({
  autoFocus = false,
  shoppingCart,
  zip,
}: LicensePlateOrVinInputProps) => {
  const [accessToken] = useCookie<string>(CookieKeys.ACCESS_TOKEN);
  const isAuthenticated = !!accessToken;
  const { pathname } = useLocation();
  const [currentInput, setCurrentInput] = useState(LICENSE_PLATE_NAME);
  const [dealExists, setDealExists] = useState(false);
  const [showOldCarWarning, setShowOldCarWarning] = useState(false);
  const isLicensePlate = LICENSE_PLATE_NAME === currentInput;
  // eslint-disable-next-line @typescript-eslint/no-unused-vars
  const widgetButtonCTAColor = useFlag(LDFlags.WIDGET_BUTTON_CTA_COLOR);

  const handleWarningsReset = () => {
    setDealExists(false);
    setShowOldCarWarning(false);
  };

  const handleOnSwitchChange = (
    setErrors: setErrors,
    setValues: setValues,
    newValue: string,
  ): void => {
    setCurrentInput(newValue);
    setValues({ vin: '', license_plate_number: '', state: '' });
    setErrors({});
  };

  const handleSubmit = async (values: FormFields) => {
    const queryParams = createQueryParams(values, shoppingCart, zip);

    // eslint-disable-next-line security/detect-non-literal-fs-filename
    window.open(`${FLOW_ENTRY}?${queryParams}`, '_self');
    rudderanalytics.track(RudderEvent.FlowEntry, {
      page: pathname,
      method: isLicensePlate ? 'License Plate' : 'VIN',
    });
  };

  const tracking = () => {
    rudderanalytics.track(RudderEvent.LabTest, {
      name: 'experiment:widget-cta-color',
      value: widgetButtonCTAColor,
    });
  };

  useEffect(() => {
    tracking();
  }, []);

  return (
    <Formik<FormFields>
      enableReinitialize
      validateOnChange={false}
      validateOnBlur={false}
      initialValues={{
        license_plate_number: '',
        state: '',
        vin: '',
      }}
      validationSchema={isLicensePlate ? licensePlateValidationSchema : vinValidationSchema}
      onSubmit={handleSubmit}
    >
      {({ isSubmitting, setErrors, setValues }) => (
        <Form style={{ width: '100%' }}>
          <Flex
            bg={shoppingCart ? 'transparent' : { base: '#ffffffcc', md: 'white' }}
            border={shoppingCart ? undefined : '1px solid'}
            borderColor={shoppingCart ? undefined : 'transparent'}
            shadow={shoppingCart ? undefined : 'lg'}
            mx="auto"
            maxW="370px"
            minW={{ base: '90vw', md: 'auto' }}
            rounded="10px"
            p="4"
            flexDirection="column"
          >
            <Flex alignItems="center" flexDirection={{ sm: 'column', md: 'row' }}>
              <Text
                noOfLines={2}
                mr={{ md: '3' }}
                textAlign={{ base: 'center', md: 'left' }}
                mt={1}
                mb={{ sm: '6', md: '0' }}
              >
                GET STARTED WITH YOUR {isLicensePlate ? 'LICENSE PLATE' : `VEHICLE'S VIN`}
              </Text>
              <Flex h="40px" ml={{ md: '1' }} minW="170px">
                <Switch
                  firstOption={LICENSE_PLATE_NAME}
                  secondOption={VIN_NAME}
                  onChange={(val) => {
                    handleOnSwitchChange(setErrors, setValues, val);
                    handleWarningsReset();
                  }}
                />
              </Flex>
            </Flex>
            <Flex mt="4" mb="2" minH="50px">
              {isLicensePlate ? (
                <>
                  <Input
                    name="license_plate_number"
                    placeholder="License Plate"
                    pr="5px"
                    autoFocus={autoFocus}
                    textTransform="uppercase"
                    onChange={handleWarningsReset}
                    invalid={dealExists || showOldCarWarning}
                  />
                  <Flex maxW={{ sm: '110px', md: '100%' }}>
                    <Select
                      name="state"
                      placeholder="State"
                      options={statesArray}
                      onChange={handleWarningsReset}
                      invalid={dealExists}
                    />
                  </Flex>
                </>
              ) : (
                <Input
                  name="vin"
                  placeholder="VIN"
                  textTransform="uppercase"
                  onChange={handleWarningsReset}
                  invalid={dealExists || showOldCarWarning}
                />
              )}
            </Flex>
            {showOldCarWarning && (
              <Text
                as="span"
                color="red.500"
                fontWeight="semibold"
                textAlign="center"
                fontSize="12px"
              >
                We are typically unable to secure financing for leases that are {YEAR_DIFF}+ years
                old. Thank you.
              </Text>
            )}
            {!dealExists && !showOldCarWarning && (
              <Flex direction="column" alignItems="center">
                <Button
                  type="submit"
                  w="100%"
                  py="20px"
                  fontWeight="600"
                  isLoading={isSubmitting}
                  style={{
                    backgroundColor: widgetButtonCTAColor,
                  }}
                >
                  END YOUR LEASE
                </Button>
              </Flex>
            )}
            {!isAuthenticated && (
              <Subtitle
                textAlign="center"
                lineHeight="25px"
                fontSize="14px"
                maxW={{ base: '350px', lg: '750px' }}
                margin="10px"
                fontWeight="bold"
              >
                Already have an account?{' '}
                <Link
                  to={ACCOUNT_LOGIN}
                  color="royalBlue"
                  fontSize="14px"
                  letterSpacing={0}
                  fontWeight="medium"
                >
                  Login now
                </Link>
              </Subtitle>
            )}
          </Flex>
        </Form>
      )}
    </Formik>
  );
};

export default LicensePlateOrVinInput;

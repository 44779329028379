import { Grid, GridItem } from '@chakra-ui/react';
import React from 'react';

import { statesArray } from '../../constants/states';
import AutocompleteGoogleAddress from './AutocompleteGoogleAddress';
import Input from './Input';
import Select from './Select';

interface AddressInputProps {
  label?: string;
  prefix?: string;
  dropLabel?: boolean;
}

const AddressInput = ({ label, prefix, dropLabel }: AddressInputProps) => {
  const formattedPrefix = prefix ? `${prefix}_` : '';

  return (
    <Grid
      templateColumns={{
        base: '1fr',
        md: '1fr 1fr 1fr',
      }}
      gridColumnGap="16px"
      gridRowGap="20px"
      alignItems={dropLabel ? 'start' : 'end'}
    >
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <AutocompleteGoogleAddress
          label={label}
          prefix={formattedPrefix}
          name={`${formattedPrefix}address_line`}
          placeholder="Street"
          dropLabel={dropLabel}
        />
      </GridItem>
      <GridItem colSpan={1}>
        <Input
          name={`${formattedPrefix}address_line_2`}
          placeholder="Apt., Suite, Unit"
          _input={{
            autoComplete: 'address-line2',
          }}
        />
      </GridItem>
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <Input name={`${formattedPrefix}city`} placeholder="City" />
      </GridItem>
      <GridItem colSpan={1}>
        <Select name={`${formattedPrefix}state`} options={statesArray} placeholder="State" />
      </GridItem>
      <GridItem
        colSpan={{
          base: 1,
          md: 2,
        }}
      >
        <Input name={`${formattedPrefix}zip`} placeholder="Zip" />
      </GridItem>
      <GridItem colSpan={1}>
        <Input name={`${formattedPrefix}county`} placeholder="County" />
      </GridItem>
    </Grid>
  );
};

export default AddressInput;

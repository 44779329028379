import { gql } from '@apollo/client';

export const updateSsnAndAccountNumber = gql`
  mutation updateSsnAndAccountNumber($deal_id: ID!, $ssn: String, $account_number: String) {
    updateSsnAndAccountNumber(deal_id: $deal_id, ssn: $ssn, account_number: $account_number)
  }
`;

export const customerUpdate = gql`
  mutation customerUpdate($id: ID, $customer: customerInput) {
    customerUpdate(id: $id, customer: $customer) {
      id
    }
  }
`;

export const getByAuth0Id = gql`
  query getByAuth0Id {
    getByAuth0Id {
      first_name
      last_name
      full_name
      email
    }
  }
`;

export const linkAccount = gql`
  mutation linkAccount($ssn_last_4: String, $deal_id: ID, $is_cobuyer: Boolean) {
    linkAccount(ssn_last_4: $ssn_last_4, deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;

export const optOut = gql`
  mutation optOut($deal_id: ID, $is_cobuyer: Boolean) {
    optOut(deal_id: $deal_id, is_cobuyer: $is_cobuyer)
  }
`;

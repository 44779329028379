import { Box, FormControl, FormControlProps, FormErrorMessage, useToken } from '@chakra-ui/react';
import { useField, useFormikContext } from 'formik';
import React, { useEffect, useState } from 'react';
import GooglePlacesAutocomplete, { geocodeByAddress } from 'react-google-places-autocomplete';

import {
  decodeGoogleApiResponse,
  fillAddressFieldsWithGoogleAutocomplete,
} from '../../utils/address';
import Input from './Input';
import Label from './Label';

interface Props extends FormControlProps {
  label?: string;
  prefix?: string;
  name: string;
  placeholder: string;
  dropLabel?: boolean;
}

const AutocompleteGoogleAddress = ({
  label,
  prefix = '',
  name,
  placeholder,
  dropLabel,
  ...rest
}: Props) => {
  const { setFieldValue } = useFormikContext();
  const [field, meta, { setValue }] = useField({ name });
  const isInvalid = meta.touched && meta.error != null;
  const { value } = field;

  const [googleAddress, setGoogleAddress] = useState<Record<string, unknown> | null>(null);
  const [fallbackValue, setFallbackValue] = useState<string>('');
  const [autocompleted, setAutocompleted] = useState<boolean>(false);

  useEffect(() => {
    if (!value) {
      setAutocompleted(false);
    }
  }, [value]);

  useEffect(() => {
    if (value && !googleAddress) {
      setGoogleAddress({ label: value, value: { place_id: '' } });
    }
  }, [value]);

  useEffect(() => {
    const spaceDiv = document.querySelector('.css-ok7aic') as HTMLElement;
    if (spaceDiv) {
      spaceDiv.style.display = 'none';
    }
  }, []);

  const [
    oceanBoatBlue,
    grayDark,
    grayDarkBackground,
    grayDisabledBackground,
    leaseEndRed,
    charcoal,
    leaseEndRedBG,
  ] = useToken('colors', [
    'oceanBoatBlue',
    'grayDark',
    'grayDarkBackground',
    'grayDisabledBackground',
    'leaseEndRed',
    'charcoal',
    'leaseEndRedBG',
  ]);

  if (!process.env.REACT_APP_GOOGLE_MAPS_API_KEY || autocompleted) {
    return <Input name={name} placeholder={placeholder} label={!dropLabel ? 'ADDRESS' : ''} />;
  }

  return (
    <FormControl isInvalid={isInvalid} {...rest}>
      {!dropLabel && <Label>{label ?? 'ADDRESS'}</Label>}
      <GooglePlacesAutocomplete
        apiKey={process.env.REACT_APP_GOOGLE_MAPS_API_KEY}
        autocompletionRequest={{
          types: ['address'],
          componentRestrictions: { country: 'us' },
        }}
        selectProps={{
          ...field,
          placeholder,
          styles: {
            option: (provided, state) => ({
              ...provided,
              backgroundColor: state.isFocused ? grayDisabledBackground : 'none',
              color: grayDark,
            }),
            dropdownIndicator: () => ({
              display: 'none',
            }),
            indicatorSeparator: () => ({
              display: 'none',
            }),
            control: (provided, state) => ({
              ...provided,
              fontSize: '16px',
              height: '20px',
              minHeight: '35px',
              boxShadow: 'none',
              borderRadius: '3px',
              color: charcoal,
              backgroundColor: isInvalid ? leaseEndRedBG : 'white',
              borderColor: isInvalid
                ? leaseEndRed
                : state.isFocused
                ? oceanBoatBlue
                : grayDarkBackground,
              '&:hover': {
                border: '',
              },
            }),
            input: (provided) => ({
              ...provided,
              color: isInvalid ? leaseEndRed : oceanBoatBlue,
              autoComplete: 'address-line1',
            }),
            valueContainer: (provided) => ({
              ...provided,
              padding: '0',
              paddingInlineStart: '10px',
            }),
          },
          value: googleAddress ? { ...googleAddress, label: value } : null,
          onChange: async (newValue) => {
            if (newValue) {
              setFallbackValue('');
              setGoogleAddress(newValue);

              const result = await geocodeByAddress(newValue?.label);
              const decodedAddress = decodeGoogleApiResponse(result[0]?.address_components);
              fillAddressFieldsWithGoogleAutocomplete(
                decodedAddress,
                `${prefix}address_line`,
                `${prefix}city`,
                `${prefix}county`,
                `${prefix}state`,
                `${prefix}zip`,
                setFieldValue,
              );
              setAutocompleted(true);
            }
          },
          onInputChange: (newValue) => {
            setFallbackValue(newValue);
          },
          onBlur: (e: React.SyntheticEvent) => {
            if (fallbackValue) {
              setValue(fallbackValue);
            }
            field.onBlur(e);
          },
        }}
      />

      <Box mt="5px" ml="5px">
        {isInvalid && <FormErrorMessage color="leaseEndRed">{meta.error}</FormErrorMessage>}
      </Box>
    </FormControl>
  );
};

export default AutocompleteGoogleAddress;

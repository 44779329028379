import { useEffect, useState } from 'react';

const COOKIE_LIFETIME = 30; // days
const DOMAIN = window.location.hostname.split('.').slice(-2).join('.').split(':')[0];

export enum CookieKeys {
  GUID_KEY = 'le_guid',
  ACCESS_TOKEN = 'le_access_token',
}

export const clearCookie = (name: CookieKeys) => {
  document.cookie = `${name}=; expires=Thu, 01 Jan 1970 00:00:00 UTC; Secure; path=/; Domain=${DOMAIN};`;
};

export const getCookie = <T>(name: CookieKeys) => {
  const cookie = document.cookie.split('; ').find((row) => row.startsWith(name));

  return cookie ? (JSON.parse(cookie.split('=')[1] || '') as T) : null;
};

const setCookie = <T>(name: CookieKeys, value: T, days: number) => {
  const date = new Date();
  date.setTime(date.getTime() + days * 24 * 60 * 60 * 1000);
  const expires = `expires=${date.toUTCString()}`;
  document.cookie = `${name}=${JSON.stringify(
    value,
  )}; ${expires}; Secure; path=/;  Domain=${DOMAIN};`;
};

export const useCookie = <T>(name: CookieKeys) => {
  const [cookieValue, setCookieState] = useState<T | null>(getCookie<T>(name));

  const setCookieValue = (value: T) => {
    setCookie(name, value, COOKIE_LIFETIME);
    setCookieState(value);
  };

  useEffect(() => {
    const interval = setInterval(() => {
      if (getCookie(name) !== cookieValue) {
        setCookieState(getCookie(name));
      }
    }, 1000);

    return () => {
      clearInterval(interval);
    };
  }, [name]);

  return [cookieValue, setCookieValue] as const;
};

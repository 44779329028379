/* eslint-disable no-underscore-dangle */

/* eslint-disable no-unused-vars */
import { Box, Container, Flex, Link } from '@chakra-ui/react';
import { PortableText } from '@portabletext/react';
import moment from 'moment';
import { useEffect, useState } from 'react';
import { useLocation, useParams } from 'react-router-dom';

import Description from '../../components/Description';
import Title from '../../components/Title';
import TitleContainer from '../../components/TitleContainer';
import Loading from '../Loading';
import BlogImageComponent from '../Sanity/Blog/BlogImageComponent';
import { postFetch } from '../Sanity/Data';
import { BlogPostProps, ptBlogComponents } from '../Sanity/Model';

const BlogPost = () => {
  const { slug } = useParams<{ slug: string }>();
  const location = useLocation();
  const [loading, setLoading] = useState<boolean>(false);
  const [post, setPost] = useState<BlogPostProps | null>(null);

  const fetch = async () => {
    setLoading(true);
    const data: BlogPostProps | null = await postFetch(slug);
    setLoading(false);
    setPost(data);
  };

  const seo = () => {
    if (!document.querySelector('meta[name="description"]')) {
      const desc = document.createElement('meta');
      desc.name = 'description';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.description || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="description"]')
        ?.setAttribute('content', post?.description || '');
    }
    if (!document.querySelector('meta[name="keywords"]')) {
      const desc = document.createElement('meta');
      desc.name = 'keywords';
      desc.httpEquiv = 'X-UA-Compatible';
      desc.content = post?.keywords || '';
      document.getElementsByTagName('head')[0].appendChild(desc);
    } else {
      document
        .querySelector('meta[name="keywords"]')
        ?.setAttribute('content', post?.keywords || '');
    }
    document.title = post?.browserTitle || document.title;
  };

  useEffect(() => {
    if (post) {
      seo();
    }
  }, [post]);

  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
      {loading ? (
        <Loading />
      ) : post ? (
        <>
          <Flex
            alignItems="flex-start"
            justifyContent="flex-start"
            flexDirection="column"
            maxWidth={760}
            mx="auto"
          >
            {/* Post title */}
            <TitleContainer alignContent="center" justifyContent="flex-start" flexDirection="row">
              <Title textAlign="left" fontSize={{ base: '36px', md: '52px' }}>
                {post.title || 'no title'}
              </Title>
            </TitleContainer>
            {/* Author */}
            <Box>
              {post.authorImage && <BlogImageComponent value={post.authorImage} isInline />}
              <Link href={`/author/${post.authorSlug}?ref=${location.pathname}`}>
                {post.authorName}
              </Link>
            </Box>
            {/* Publish date */}
            <Box>{moment(new Date(post.publishDate)).format('M/D/YY h:mma')}</Box>
            {/* Post body */}
            <Flex
              flexDirection="column"
              alignItems="flex-start"
              alignContent="flex-start"
              textAlign="left"
            >
              <Description color="leaseEndBlue" textAlign="left" fontWeight="500">
                <PortableText value={post.body} components={ptBlogComponents} />
              </Description>
            </Flex>
          </Flex>
        </>
      ) : (
        <Box>Sorry, we couldn't load this post</Box>
      )}
    </Container>
  );
};

export default BlogPost;

import {
  Box,
  BoxProps,
  Input as ChakraInput,
  FormControl,
  FormControlProps,
  FormErrorMessageProps,
  FormHelperText,
  FormHelperTextProps,
  FormLabelProps,
  InputGroup,
  InputProps,
  InputRightElement,
  PropsOf,
  StackProps,
  VStack,
} from '@chakra-ui/react';
import { useField } from 'formik';
import React, { useState } from 'react';
import InputMask from 'react-input-mask';

import FormErrorMessage from './FormErrorMessage';
import Label from './Label';

interface Props extends Omit<FormControlProps, 'label'> {
  name: NonNullable<InputProps['name']>;
  placeholder?: NonNullable<InputProps['placeholder']>;
  type?: NonNullable<InputProps['type']>;
  label?: React.ReactNode;
  fontSize?: InputProps['fontSize'];
  maxLength?: NonNullable<InputProps['maxLength']>;
  debounce?: boolean;
  autoFocus?: boolean;
  _input?: PropsOf<typeof ChakraInput>;
  _container?: StackProps;
  _errorMessageContainer?: BoxProps;
  _errorMessage?: FormErrorMessageProps;
  _helperText?: FormHelperTextProps;
  labelProps?: FormLabelProps;
  mask?: string;
  value?: string;
  readOnly?: boolean;
  icon?: React.ReactNode;
  helperText?: React.ReactNode;
  size?: InputProps['size'];
  invalid?: boolean;
}

const Input = ({
  name,
  placeholder,
  type = 'text',
  label,
  fontSize,
  maxLength,
  debounce,
  autoFocus = false,
  textTransform = 'none',
  _input,
  _container,
  _errorMessageContainer,
  _errorMessage,
  _helperText,
  labelProps,
  mask = '',
  value,
  readOnly,
  icon,
  helperText,
  size,
  invalid = false,
  ...rest
}: Props) => {
  const [field, meta, helpers] = useField(name);
  const [t, setT] = useState<number | null>(null);
  const [tempValue, setTempValue] = useState<string>('');

  const { checked, onBlur, multiple } = field;
  const { setValue } = helpers;

  const handleChange = (e: React.ChangeEvent<HTMLInputElement>) => {
    let { value: text } = e.target;
    if (maxLength && text.length > maxLength) {
      text = text.slice(0, maxLength);
    }
    if (debounce) {
      if (t) clearTimeout(t);
      setTempValue(text);
      setT(window.setTimeout(() => setValue(text), 500));
    } else {
      setValue(text);
    }
  };

  const isInvalid = (meta.touched && meta.error != null) || invalid;

  return (
    <VStack
      w="100%"
      spacing={0}
      h="100%"
      pointerEvents={readOnly ? 'none' : 'auto'}
      {..._container}
    >
      <FormControl isInvalid={isInvalid} {...rest}>
        {label && <Label {...labelProps}>{label}</Label>}
        <InputGroup>
          <ChakraInput
            as={InputMask}
            mask={field.value ? mask : undefined}
            type={type}
            placeholder={placeholder}
            isInvalid={isInvalid}
            size={size}
            fontSize={fontSize}
            maxLength={maxLength}
            checked={checked}
            name={name}
            onBlur={(values: React.ChangeEvent<HTMLInputElement>) => {
              onBlur(values);
              handleChange(values);
            }}
            onChange={handleChange}
            value={debounce ? tempValue : value || field.value}
            multiple={multiple}
            autoFocus={autoFocus}
            textTransform={textTransform}
            readOnly={readOnly}
            pr={icon ? 8 : 4}
            {..._input}
          />
          {icon && <InputRightElement h="100%">{icon}</InputRightElement>}
        </InputGroup>
        {(isInvalid || helperText) && (
          <Box mt="5px" ml="5px" {..._errorMessageContainer}>
            {isInvalid ? (
              <FormErrorMessage color="leaseEndRed" {..._errorMessage}>
                {meta.error}
              </FormErrorMessage>
            ) : (
              <FormHelperText color="taupeGray" textAlign="left" {..._helperText}>
                {helperText}
              </FormHelperText>
            )}
          </Box>
        )}
      </FormControl>
    </VStack>
  );
};

export default Input;

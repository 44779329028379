import { Box, Heading, Link } from '@chakra-ui/react';

import { RudderEvent, rudderanalytics } from '../../../utils/rudderstack';

const TempTest = () => {
  const doClick = () => {
    rudderanalytics.track(RudderEvent.LabTestSuccess, {
      event: 'tempTestClick',
    });
    // eslint-disable-next-line no-console
    console.log('Hi');
    return true;
  };

  return (
    <>
      <Box>
        <Heading
          fontWeight="semibold"
          fontSize={{ base: 25, md: 35 }}
          textAlign="center"
          color="leaseEndBlue"
          mb={10}
        >
          Temp test page
        </Heading>
      </Box>
      <Box>
        <Link onClick={() => doClick()}>Click Me</Link>
      </Box>
    </>
  );
};

export default TempTest;

import { Link, ToastProps, UseToastOptions } from '@chakra-ui/react';
import mixpanel from 'mixpanel-browser';
import React from 'react';
import { getPhoneNumber } from '../hooks/useSearchPhoneNumbers';
import { rudderanalytics, RudderEvent } from './rudderstack';

export const TOAST_DURATION = 30000;

export interface ToastOpts {
  errorMessage?: string;
  title?: ToastProps['title'];
  description?: React.ReactNode;
}

export const createErrorToast = ({ errorMessage, title, description }: ToastOpts) => {
  const phoneNumber = getPhoneNumber();
  const toastOptions: UseToastOptions = {
    title: title || 'Something seems to have gone wrong on our end.',
    description: description || (
      <>
        You can refresh this page and try again later, or call us at{' '}
        <Link
          _hover={{ color: 'inherit', textDecoration: 'underline' }}
          href={`tel:+1-${phoneNumber}`}
          color="inherit"
        >
          {phoneNumber}
        </Link>{' '}
        to speak with one of our expert advisors.
      </>
    ),
    position: 'top-right',
    status: 'error',
    duration: TOAST_DURATION,
    isClosable: true,
    onCloseComplete: () => {
      if (!errorMessage) {
        return;
      }

      const { pathname } = window.location;

      mixpanel.track(`Error on COM page ${pathname}`, {
        errorMessage,
        pathname,
      });
      rudderanalytics.track(RudderEvent.Error, {
        error_message: errorMessage,
        pathname,
      });
    },
  };
  return toastOptions;
};

export const createSuccessToast = ({
  title = 'Success!',
  description = '',
}: Omit<ToastOpts, 'errorMessage'>) => {
  const toastOptions: UseToastOptions = {
    title,
    description,
    position: 'top-right',
    status: 'success',
    duration: TOAST_DURATION,
    isClosable: true,
  };
  return toastOptions;
};

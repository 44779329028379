import { gql } from '@apollo/client/core';

// Add more deal attributes here if needed
export const updateDeal = gql`
  mutation updateDeal($auto_import_variation: autoImportVariation) {
    updateDeal(auto_import_variation: $auto_import_variation) {
      id
    }
  }
`;

export const dealUpdateMissingRequiredDocumentsCom = gql`
  mutation dealUpdateMissingRequiredDocumentsCom($missing_required_external_documents: Boolean) {
    dealUpdateMissingRequiredDocumentsCom(
      missing_required_external_documents: $missing_required_external_documents
    )
  }
`;

export const dealAdvisors = gql`
  query dealAdvisors($id: ID) {
    dealAdvisors(id: $id) {
      setter {
        name
      }
      closer {
        name
      }
      funding_clerk {
        name
      }
      title_clerk {
        name
      }
    }
  }
`;

export const trackComEvent = gql`
  mutation trackComEvent(
    $approval_on_deal_processing: Boolean
    $reached_documents_screen: Boolean
    $finished_documents_screen: Boolean
  ) {
    trackComEvent(
      approval_on_deal_processing: $approval_on_deal_processing
      reached_documents_screen: $reached_documents_screen
      finished_documents_screen: $finished_documents_screen
    )
  }
`;

export const totalCompletedDeals = gql`
  query totalCompletedDeals {
    totalCompletedDeals
  }
`;

export const getUnlinkedDeals = gql`
  query getUnlinkedDeals {
    getUnlinkedDeals {
      id
      car {
        year
        make
        model
        vin
        vehicle_type
        color
      }
      isCobuyer
    }
  }
`;

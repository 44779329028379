/* eslint-disable prettier/prettier */
import { Box, Container, Spacer } from '@chakra-ui/react';
import { useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';

import Title from '../../components/Title';
import Loading from '../Loading';
import { blogFetch } from '../Sanity/Data';
import BlogPostSnippet from './Snippet';

const Blog = () => {
  const [loading, setLoading] = useState<boolean>(false);
  const params: {
    tag: string;
  } = useParams();
  const [posts, setPosts] = useState<[] | null>(null);
  const fetch = async () => {
    setLoading(true);
    const data: [] | null = await blogFetch(params && params.tag ? params.tag : null);
    setLoading(false);
    setPosts(data);
  };
  useEffect(() => {
    fetch();
  }, []);

  return (
    <Container color="leaseEndBlue" maxW="container.xl" textAlign="center" px={{ md: 16 }}>
      <Box
        p={{ md: '0 75px 0', lg: '0 100px 0' }}
        style={{
          textAlign: 'left',
        }}
      >
        <Title fontSize={{ base: '32px', md: '42px' }} textAlign="left">
          Lease End Blog
        </Title>
        {loading ? (
          <Loading />
        ) : (
          <>
            {!posts ? (
              <Box>No blog posts found</Box>
            ) : (
              posts.map((post, x: number) => {
                // eslint-disable-next-line react/no-array-index-key
                return <BlogPostSnippet post={post} key={x} />;
              })
            )}
          </>
        )}
        <Spacer h={{ base: 50, md: 100 }} />
      </Box>
    </Container>
  );
};

export default Blog;

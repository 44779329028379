import groq from 'groq';

import client from '../../client';
import { AuthorProps, BlogPostProps, FAQProps, FeaturedPostProps, SanityPageProps } from './Model';

export const pageFetch = async (page: string) => {
  const data: SanityPageProps[] | null = await client.fetch(
    groq`
    *[_type == "page" && title=='${page}']{
        title,
        subtitle,
        heading,
        widget,
        body
      }
  `,
  );
  return data;
};

export const faqFetch = async () => {
  const data: FAQProps[] | null = await client.fetch(
    groq`
      *[_type == "faq"]{
        question,
        answer,
        "publishDate": _updatedAt,
      }
  `,
  );
  return data;
};

export const blogFetch = async (category?: string | null) => {
  // eslint-disable-next-line no-underscore-dangle
  const _category = category ? ` && '${category}' in categories[]->title` : '';
  const data: [] | null = await client.fetch(
    groq`
        *[_type == "post" && published${_category}] | order(publishDate desc){
          title,
          "publishDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          slug,
          "mainImage": mainImage
        }
    `,
  );
  return data;
};

export const postFetch = async (slug: string) => {
  const data: BlogPostProps | null = await client.fetch(
    groq`
          *[_type == "post" && slug.current == $slug][0]{
            title,
            "publishDate": _updatedAt,
            "authorName": author->name,
            "authorSlug": author->slug.current,
            "authorImage": author->image,
            "categories": categories[]->title,
            body,
            description,
            keywords,
            browserTitle,
            mainImage
          }
      `,
    { slug },
  );
  return data;
};

export const featuredPostFetch = async () => {
  const data: FeaturedPostProps | null = await client.fetch(
    groq`
          *[_type == "post" && homepage] | order(_updatedAt desc)[0]{
            title,
          "publishDate": _updatedAt,
          "authorName": author->name,
          "authorSlug": author->slug.current,
          "authorImage": author->image,
          "categories": categories[]->title,
          snippet,
          slug,
          "mainImage": mainImage,
          "featured": homepage
          }
      `,
  );
  return data;
};

export const authorFetch = async (slug: string, flavor?: string) => {
  const limit: string = flavor && flavor === 'recent' ? '[0...3]' : '';
  const data: AuthorProps | null = await client.fetch(
    groq`
          *[_type == "author" && slug.current == $slug][0]{
            "authorName": name,
            "authorImage": image,
            "authorSlug": slug.current,
            "authorBio": bio,
            "posts": *[_type == "post" && references(^._id) ]${limit}{
              title,
              "slug": slug.current,
              "publishDate": _updatedAt,
            }
          }
      `,
    { slug },
  );
  return data;
};

import React from 'react';
import { Heading, HeadingProps } from '@chakra-ui/react';

const Title: React.FC<HeadingProps> = ({ children, ...props }) => (
  <Heading
    as="h1"
    fontWeight="semibold"
    color="leaseEndBlue"
    fontSize="36px"
    textAlign="center"
    {...props}
  >
    {children}
  </Heading>
);

export const ShortTitle: React.FC<HeadingProps> = ({ children }) => (
  <Title maxWidth={{ base: '250px', lg: 'none' }}>{children}</Title>
);

export default Title;

export enum Images {
  // Press
  FactSheet = 'FactSheet.svg',
  GreenCircleMountain = 'GreenCircleMountain.svg',
  ManWithLoudSpeaker = 'ManWithLoudSpeaker.svg',
  OurTeam = 'OurTeam.svg',
  DownloadArrowBlue = 'download_arrow_blue.svg',

  // About us
  AboutUsLeaseOwner = 'about-us/lease_owner.png',

  // About us - bios
  AboutUsBrandonWilliams = 'about-us/Brandon_Williams.svg',
  AboutUsZanderCook = 'about-us/Zander_Cook.svg',
  AboutUsDaveWilliams = 'about-us/Dave_Williams.svg',
  AboutUsJeremyToner = 'about-us/Jeremy_Toner.svg',
  AboutUsChipCook = 'Chip.svg',

  //
  PointingPhoneGuy = 'pointing_phone_guy.svg',
  OkGestureGuy = 'ok_gesture_guy.svg',

  // Contact Us
  Idaho = 'Idaho.svg',

  // Home
  Arrow = 'arrow.svg',
  BestDecision = 'bestdecision.webp',
  PaymentCalculatorIcon = 'payment-calculator-icon.svg',
  Google = 'google.svg',
  GoogleReviews = 'google-reviews.svg',
  FiveStars = '5-stars.svg',
  Quote = 'quote.svg',
  HomePageSimpleBackgroundWideSVG = 'home-page-simple-bg.svg',
  BlueCar = 'BlueCar.svg',
  CarPhoneLady = 'CarPhoneLady.svg',
  DocumentSignatures = 'DocumentSignatures.svg',
  NoDealership = 'NoDealership.svg',
  OverMileage = 'odometer_red.svg',
  PhoneGuy = 'PhoneGuy.svg',
  PiggyBank = 'PiggyBank.svg',
  ResidualValue = 'residualvalue.webp',
  SleepyCouch = 'SleepyCouch.svg',
  SparkAward = 'SparkAward.png',
  StandardFees = 'standardfees.webp',
  Tags = 'Tags.svg',
  VehicleDamage = 'VehicleDamage.svg',
  TrustPilotIcon = 'trust-pilot-icon.svg',
  Inc5000 = 'inc_5000.svg',
  CarDude2 = 'car_dude2.svg',
  BestMoneyLogo = 'best_money_logo.svg',

  // How It Works
  LeaseEndGuyGivingMoney = 'lease-end-guy-giving-money.svg',
  LeaseEndCarHauler = 'lease-end-car-hauler.svg',
  LeaseEndGuyHuggingCar = 'lease-end-guy-hugging-car.svg',
  GreenArrow = 'green-arrow.svg',

  // Lienholder Verification
  MagnifyingGlass = 'magnifying_glass.svg',

  // Mileage
  Odometer = 'odometer.svg',

  // Nav
  Logo = 'lease-end-logo.png',
  SmallLogo = 'small-lease-end-logo.png',

  // VSC Selection
  AllyColor = 'lienholders/Finance_Logos_Ally_Color.png',

  // Reviews
  BBB = 'bbb_logo.svg',
  FacebookLogo = 'f_logo_RGB-Blue_250.png',

  // Unlock Your Equity
  GreenHandKey = 'greenHandKey.svg',
  GreenTag = 'greenTag.svg',

  // Health Tracker
  HealthTracker = 'HealthTracker.svg',
  CarEKG = 'car-ekg.svg',

  // 404 Page
  Background404 = '404Page_Background.svg',
  Foreground404 = '404Page_Foreground.svg',

  // Lease Peace
  AntiBot = 'AntiBot.png',
  Dollaritas = 'Dollaritas.svg',
  GreenHandKeyWithOutline = 'greenHandKeyWithOutline.png',
  LeasePeaceBackground = 'LeasePeaceBackground.svg',
  LeasePeaceMeditator = 'LeasePeaceMeditator.svg',
  LeasePeaceSymbol = 'LeasePeaceSymbol.svg',
  LeasePeaceVan = 'LeasePeaceVan.svg',
  OdometerPointer = 'OdometerPointer.png',
  ResultsCheck = 'ResultsCheck.svg',

  // Valentine Banner
  BeMyCarHeart = 'be-my-car-heart.svg',
  DriveMeWildHeart = 'drive-me-wild-heart.svg',
  WinHeart = 'win-heart.svg',
  ValentineGuy = 'valentine-guy.svg',

  // bank logos
  AllyBankLogo = 'banks/ally_logo.png',
  CapitalOneBankLogo = 'banks/capital_one_logo.svg',
  FirstInterstateBankLogo = 'banks/first_interstate_bank_logo.png',
  MacuBankLogo = 'banks/macu_logo.png',
  TdBankLogo = 'banks/td_bank_logo.png',
}

export const getImage = (i: string): string => `/leaseend-com-images/${i}`;

export const FactSheet = getImage(Images.FactSheet);
export const GreenCircleMountain = getImage(Images.GreenCircleMountain);
export const ManWithLoudSpeaker = getImage(Images.ManWithLoudSpeaker);
export const OurTeam = getImage(Images.OurTeam);
export const DownloadArrowBlue = getImage(Images.DownloadArrowBlue);
export const AboutUsLeaseOwner = getImage(Images.AboutUsLeaseOwner);
export const AboutUsBrandonWilliams = getImage(Images.AboutUsBrandonWilliams);
export const AboutUsZanderCook = getImage(Images.AboutUsZanderCook);
export const AboutUsDaveWilliams = getImage(Images.AboutUsDaveWilliams);
export const AboutUsJeremyToner = getImage(Images.AboutUsJeremyToner);
export const AboutUsChipCook = getImage(Images.AboutUsChipCook);
export const Arrow = getImage(Images.Arrow);
export const BBBLogo = getImage(Images.BBB);
export const BestDecision = getImage(Images.BestDecision);
export const BlueCar = getImage(Images.BlueCar);
export const GoogleLogo = getImage(Images.Google);
export const GoogleReviews = getImage(Images.GoogleReviews);
export const BestMoneyLogo = getImage(Images.BestMoneyLogo);
export const CarDude2 = getImage(Images.CarDude2);
export const CarPhoneLady = getImage(Images.CarPhoneLady);
export const DocumentSignatures = getImage(Images.DocumentSignatures);
export const FacebookLogo = getImage(Images.FacebookLogo);
export const FiveStars = getImage(Images.FiveStars);
export const GreenHandKey = getImage(Images.GreenHandKey);
export const GreenTag = getImage(Images.GreenTag);
export const HomePageSimpleBackgroundWideSVG = getImage(Images.HomePageSimpleBackgroundWideSVG);
export const LeaseEndGuyGivingMoney = getImage(Images.LeaseEndGuyGivingMoney);
export const LeaseEndCarHauler = getImage(Images.LeaseEndCarHauler);
export const LeaseEndGuyHuggingCar = getImage(Images.LeaseEndGuyHuggingCar);
export const GreenArrow = getImage(Images.GreenArrow);
export const Idaho = getImage(Images.Idaho);
export const Logo = getImage(Images.Logo);
export const SmallLogo = getImage(Images.SmallLogo);
export const MagnifyingGlass = getImage(Images.MagnifyingGlass);
export const NoDealership = getImage(Images.NoDealership);
export const Odometer = getImage(Images.Odometer);
export const OkGestureGuy = getImage(Images.OkGestureGuy);
export const OverMileage = getImage(Images.OverMileage);
export const PaymentCalculatorIcon = getImage(Images.PaymentCalculatorIcon);
export const PhoneGuy = getImage(Images.PhoneGuy);
export const PiggyBank = getImage(Images.PiggyBank);
export const PointingPhoneGuy = getImage(Images.PointingPhoneGuy);
export const Quote = getImage(Images.Quote);
export const ResidualValue = getImage(Images.ResidualValue);
export const SleepyCouch = getImage(Images.SleepyCouch);
export const SparkAward = getImage(Images.SparkAward);
export const StandardFees = getImage(Images.StandardFees);
export const Tags = getImage(Images.Tags);
export const TrustPilotIcon = getImage(Images.TrustPilotIcon);
export const VehicleDamage = getImage(Images.VehicleDamage);
export const Inc5000 = getImage(Images.Inc5000);
export const CarEKG = getImage(Images.CarEKG);
export const HealthTracker = getImage(Images.HealthTracker);
export const Background404 = getImage(Images.Background404);
export const Foreground404 = getImage(Images.Foreground404);
export const AntiBot = getImage(Images.AntiBot);
export const Dollaritas = getImage(Images.Dollaritas);
export const GreenHandKeyWithOutline = getImage(Images.GreenHandKeyWithOutline);
export const LeasePeaceBackground = getImage(Images.LeasePeaceBackground);
export const LeasePeaceMeditator = getImage(Images.LeasePeaceMeditator);
export const LeasePeaceSymbol = getImage(Images.LeasePeaceSymbol);
export const LeasePeaceVan = getImage(Images.LeasePeaceVan);
export const OdometerPointer = getImage(Images.OdometerPointer);
export const ResultsCheck = getImage(Images.ResultsCheck);

export const BeMyCarHeart = getImage(Images.BeMyCarHeart);
export const DriveMeWildHeart = getImage(Images.DriveMeWildHeart);
export const WinHeart = getImage(Images.WinHeart);
export const ValentineGuy = getImage(Images.ValentineGuy);

export const AllyBankLogo = getImage(Images.AllyBankLogo);
export const CapitalOneBankLogo = getImage(Images.CapitalOneBankLogo);
export const FirstInterstateBankLogo = getImage(Images.FirstInterstateBankLogo);
export const MacuBankLogo = getImage(Images.MacuBankLogo);
export const TdBankLogo = getImage(Images.TdBankLogo);

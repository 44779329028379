import { Flex, Text } from '@chakra-ui/react';
import React, { Dispatch, SetStateAction, useEffect } from 'react';
import { useDropzone } from 'react-dropzone';

interface DropzoneProps {
  setFileUploaded: Dispatch<SetStateAction<File[]>>;
  setFakeUploading: Dispatch<SetStateAction<boolean>>;
}

const Dropzone: React.FC<DropzoneProps> = ({ setFileUploaded, setFakeUploading }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.pdf, image/png, image/jpeg',
    noClick: true,
    noKeyboard: true,
  });

  const files = acceptedFiles.map((file) => file.name);

  useEffect(() => {
    if (acceptedFiles.length) {
      setFileUploaded(acceptedFiles);
    }
  }, [acceptedFiles, setFileUploaded]);

  useEffect(() => {
    if (acceptedFiles.length) {
      setFakeUploading(true);
    }
  }, [acceptedFiles, setFakeUploading]);

  return files.length < 1 ? (
    <Flex
      {...getRootProps({ className: 'dropzone' })}
      onClick={open}
      border="2px dashed"
      borderColor="royalBlue"
      color="royalBlue"
      mt="20px"
      m={{ base: '25px 30px 20px', lg: '25px 0 20px' }}
      borderRadius="5px"
      h="90px"
      justifyContent="center"
      alignItems="center"
      backgroundColor="semiTransparent"
      flexDir="column"
      cursor="pointer"
    >
      <input {...getInputProps()} />
      <Text display={{ base: 'block', lg: 'none' }}>TAP TO UPLOAD</Text>
      <Text display={{ base: 'none', lg: 'block' }}>DRAG AGREEMENT HERE FOR ANALYSIS</Text>
      <Text display={{ base: 'none', lg: 'block' }} color="charcoal" fontSize="14px" mt="0">
        or click to choose files
      </Text>
    </Flex>
  ) : (
    <Flex
      {...getRootProps({ className: 'dropzone' })}
      onClick={open}
      border="2px solid"
      borderColor="royalBlue"
      color="royalBlue"
      mt="20px"
      m={{ base: '25px 30px 20px', lg: '25px 0 20px' }}
      borderRadius="5px"
      h="90px"
      justifyContent="center"
      alignItems="center"
      backgroundColor="semiTransparent"
      flexDir="column"
      cursor="pointer"
    >
      <Text>"{files}" uploaded successfully</Text>
    </Flex>
  );
};

export default Dropzone;

import React, { useEffect } from 'react';
import { useDropzone } from 'react-dropzone';
import { Container } from 'semantic-ui-react';
import styled from 'styled-components';

import Description from '../Description';
import PrimaryButton from '../ui/buttons/PrimaryButton';

const StyledContainer = styled(Container)`
  margin: 30px auto 30px;
  && {
    width: 85%;
    height: 150px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
  }
  border-radius: 10px;
  border-color: ${({ theme }) => theme.colors.leaseEndBlue};
  border-style: dashed;
  border-width: 2px;
`;

const StyledDescription = styled(Description)`
  font-weight: ${({ theme }) => theme.fontWeights.medium};
  && {
    margin: 30px auto;
  }
`;

const StyledPrimaryButton = styled(PrimaryButton)`
  && {
    box-shadow: ${({ theme }) => theme.boxShadow.button};
  }
`;

const StyledDescriptionSmall = styled(Description)`
  margin-top: 20px;
  font-size: 12px;
  font-weight: ${({ theme }) => theme.fontWeights.regular};
`;

interface DropzoneProps {
  setFileUploaded: (fileUploaded: File) => void;
}

const Dropzone: React.FC<DropzoneProps> = ({ setFileUploaded }) => {
  const { getRootProps, getInputProps, open, acceptedFiles } = useDropzone({
    accept: '.pdf, image/png, image/jpeg',
    noClick: true,
    noKeyboard: true,
  });

  const files = acceptedFiles.map((file) => file.name);

  useEffect(() => {
    if (acceptedFiles.length) {
      setFileUploaded(acceptedFiles[0]);
    }
  }, [acceptedFiles, setFileUploaded]);

  return files.length < 1 ? (
    <StyledContainer {...getRootProps({ className: 'dropzone' })}>
      <input {...getInputProps()} />
      <StyledPrimaryButton type="button" onClick={open}>
        CHOOSE FILE
      </StyledPrimaryButton>
      <StyledDescriptionSmall>Or drag and drop the file here.</StyledDescriptionSmall>
    </StyledContainer>
  ) : (
    <StyledDescription>"{files}" uploaded successfully</StyledDescription>
  );
};

export default Dropzone;

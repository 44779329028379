import { useEffect } from 'react';
import { useLocation } from 'react-router-dom';

interface FlowRedirectProps {
  to: string;
}

const FlowRedirect = ({ to }: FlowRedirectProps) => {
  const { search } = useLocation();

  useEffect(() => {
    if (to) {
      window.location.href = to + search;
    }
  }, [to]);

  return null;
};

export default FlowRedirect;
